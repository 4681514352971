@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--white);
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.dt {
  display: none;
}

:root {
  --grey-black: #191919;
  --text-black: #06021f;
  --text-grey: #A0A0A1;
  --card-black: #1D1E1E;
  --white: #FFFFFF;
  --gold: #E7BF5E;
  --yellow-gold: #FFDF00;
  --grey: #FFFFFF75;
  --gradient: linear-gradient(342deg, rgba(222, 190, 100, 1) 11%, rgba(231, 228, 144, 1) 53%, rgba(230, 183, 54, 1) 94%);
  --gradient-text: linear-gradient(360deg, #001437 20.51%, rgba(0, 20, 55, 0.46) 127.91%);
  --btn-green: #38B549;
  --tag-grey: #666666;
  --black-section: #121213;
}

.app {
  background: black;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--gold);
  gap: 16px;
}

.header h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 0 0;
  gap: 8px;
  background: var(--grey-black);
  font-size: 30px;
}

.header h1 img {
  width: 42px;
  height: 42px;
}

.goldElem {
  color: var(--gradient-text);
}


.headerContentWrapper h2 {
  padding: 0;
  font-size: 26px;
}

.h2Span {
  color: var(--white);
}

.headerContentWrapper {
  padding: 0 16px;
}

.advantages-block,
.headerContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.advantCard {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.advantCard p {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--white);
}

.currentDateBlock {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.currentDate {
  font-size: 12px;
  color: var(--white);
}

.main {
  width: 100%;
}

/* first card */

.firstCardWrapper {
  padding: 8px;
}

.firstCard {
  width: 100%;
  display: flex;
  border: 2px solid var(--yellow-gold);
  border-radius: 10px;
  overflow: hidden;
}

.fcLeft,
.fcRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 8px;
}

.fcLeft {
  background: var(--card-black);
}

.fcRight {
  background: url("./assets/imgs/first_card_bg.png") no-repeat;
}

.fcTag {
  width: 100%;
  background: var(--gradient);
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: var(--text-black);
  padding: 4px;
}

.fcLogo {
  width: 100%;
  height: 100%;
  max-height: 250px;
}

.fcLogo img {
  width: 100%;
  height: auto;
}

.fcRatings {
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* padding: 0 16px; */
}

.ratingNumbers,
.stars {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rating {
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
}

.rating span {
  font-size: 12px;
  font-weight: 400;
}

.ratingText,
.testingResults {
  font-size: 10px;
  color: var(--text-grey);
  text-transform: uppercase;
  margin-top: 6px;
}

.paymentMethods {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.paymentSystems {
  display: flex;
  gap: 8px;
}

.paymentSystems img {
  width: 30px;
  height: 30px;
}

.fcMore {
  font-size: 12px;
  color: var(--white);
  text-decoration: underline;
}

.welcomeBonusTitle {
  font-size: 14px;
  font-weight: 800;
  color: var(--text-grey);
  margin-top: 24px;
  text-transform: uppercase;
}

.fcBonusDescr {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.dFirst,
.dSecond,
.dThird {
  color: var(--white);
  text-transform: uppercase;
}

.dFirst {
  font-size: 18px;
}

.dSecond {
  font-size: 36px;
  font-weight: 800;
}

.dThird {
  font-size: 18px;
}

.fcBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 16px;
  background: var(--btn-green);
  border-radius: 8px;
  padding: 24px 6px;
}

.textUnderButton {
  font-size: 12px;
  color: var(--white);
}

/* first card */

/* other cards */

.сardWrapper {
  padding: 16px;
}

.card {
  width: 100%;
  display: flex;
  border: 1px solid var(--tag-grey);
  border-radius: 10px;
  overflow: hidden;
}

.left,
.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 8px;
}

.left {
  background: var(--card-black);
}

.right {
  background: var(--black-section);
  justify-content: space-between;
  gap: 0;
}

.tag {
  width: 100%;
  background: var(--tag-grey);
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: var(--white);
  padding: 4px;
}

.logo {
  width: 100%;
  height: 100%;
  max-height: 220px;
}

.logo img {
  width: 100%;
  height: auto;
}

.ccRatings {
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* padding: 0 12px; */
}

.ratingNumbers,
.stars {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ccRating {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

.rating span {
  font-size: 10px;
  font-weight: 400;
}

.ccRatingText,
.ccTestingResults {
  font-size: 10px;
  color: var(--text-grey);
  text-transform: uppercase;
  margin-top: 6px;
}

.ccPaymentMethods {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.ccPaymentSystems {
  display: flex;
  gap: 8px;
}

.ccPaymentSystems img {
  width: 24px;
  height: 24px;
}

.ccMore {
  font-size: 10px;
  color: var(--white);
  text-decoration: underline;
}

.ccWelcomeBonusTitle {
  font-size: 10px;
  font-weight: 800;
  color: var(--text-grey);
  margin-top: 26px;
  text-transform: uppercase;
}

.ccBonusDescr {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.cdFirst,
.cdSecond,
.cdThird {
  color: var(--white);
  text-transform: uppercase;
}

.cdFirst {
  font-size: 14px;
}

.cdSecond {
  font-size: 24px;
  font-weight: 800;
}

.cdThird {
  font-size: 14px;
}

.ccBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 14px;
  background: var(--btn-green);
  border-radius: 8px;
  padding: 18px 6px;
}

.ccTextUnderButton {
  font-size: 10px;
  color: var(--white);
}

/* other cards */

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
}

.contentWrapper {
  background: var(--grey-black);
  padding: 16px;
  border-radius: 10px;
}

.contentImg {
  float: left;
  width: 200px;
  height: 200px;
  padding-right: 8px;
}

.contentImg img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.contentTitle {
  font-size: 24px;
  font-weight: 700;
  color: var(--white);
  text-align: left;
}

.contentText {
  font-size: 14px;
  color: var(--white);
  text-align: left;
}

/* footer */

.footerWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  gap: 30px;
}

.footerText {
  font-size: 8px;
  color: var(--white);
  text-align: justify;
}

.footerPartners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 30px;
}

.footerPartners img {
  width: auto;
  height: 24px;
}

/* footer */

@media (min-width: 900px) {
  body {
    background: #000;
  }

  .app {
    margin: 0 auto;
    width: 100%;
  }

  header div {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }

  main {
    width: 100%;
    max-width: 900px;
  }
}